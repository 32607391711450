import { AxiosError } from "axios";
import datadogLog from "@services/datadogLog";

const interceptWithErrorLogs = (error: AxiosError) => {
  // I'm creating a new error here to have a stack. AxiosError does not have one
  const e = new Error(error.message, { cause: error });
  e.name = error.name;

  // Separating out warnings
  const warningCases = [
    { status: 401, url: "shoppers/v1/identity/me/session/token" }, // When we aren't able to use a cookie
    { status: 404, url: "shoppers/v1/lookup_by_fingerprint" }, // When fingerprint we send isn't found
  ];

  const getErrorLevel = (status?: number, url?: string) => {
    if (status && url) {
      return warningCases.some(({ status: s, url: warnUrl }) => status === s && url.includes(warnUrl))
        ? "warn"
        : "error";
    }
    return "error";
  };

  datadogLog.logger.log(
    `[AxiosError] ${error.response?.config?.method} request to ${error.request?.responseURL} with ${error.response?.status}`,
    {
      requestDetails: {
        method: error.response?.config?.method,
        url: error.request?.responseURL,
        status: error.response?.status,
        response: error.response?.data,
      },
    },
    getErrorLevel(error.response?.status, error.request?.responseURL),
    e,
  );
  return Promise.reject(error);
};

export default interceptWithErrorLogs;
