import { Card, styled } from "@mui/material";

export const CARD_WIDTH = 152;
export const CARD_HEIGHT = 192;

const BaseCard = styled(Card)(({ theme }) => ({
  borderRadius: "12px",
  border: `1px solid ${theme.palette.primary.light}`,
  minWidth: `${CARD_WIDTH}px`,
  maxWidth: `${CARD_WIDTH}px`,
  minHeight: `${CARD_HEIGHT}px`,
  maxHeight: `${CARD_HEIGHT}px`,
  position: "relative",
  boxSizing: "border-box", // prevent size changes on select
  transition: "none",
}));

BaseCard.defaultProps = {
  elevation: 0,
};

export default BaseCard;
