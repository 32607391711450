import skipifyEvents from "@services/skipifyEvents";
import { InternalAxiosRequestConfig } from "axios";

const FLOW_TYPE_HEADER = "x-flow-type";
const SESSION_ID_HEADER = "x-amplitude-session-id";

export const getSkipifyHeaders = () => {
  const headers: Record<string, string> = {};
  if (skipifyEvents.flowType) {
    headers[FLOW_TYPE_HEADER] = skipifyEvents.flowType;
  }
  const sessionId = skipifyEvents.getSessionId();
  if (sessionId) {
    headers[SESSION_ID_HEADER] = sessionId.toString();
  }
  return headers;
};

const interceptWithSkipifyHeaders = async (requestConfig: InternalAxiosRequestConfig) => {
  const headers = getSkipifyHeaders();
  for (const [key, value] of Object.entries(headers)) {
    requestConfig.headers[key] = value;
  }
  return requestConfig;
};

export default interceptWithSkipifyHeaders;
