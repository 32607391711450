import { Box, BoxProps } from "@mui/material";
import { CARD_ART_HEIGHT, CARD_ART_WIDTH } from "./constants";
import { CreditCard } from "@mui/icons-material";

export function DefaultCardArtDisplay(props: BoxProps) {
  return (
    <Box
      height={CARD_ART_HEIGHT}
      width={CARD_ART_WIDTH}
      sx={{ borderRadius: "6px", backgroundColor: "primary.light", ...props.sx }}
      {...props}
      display="flex"
    >
      <CreditCard width="20px" height="20px" sx={{ m: "auto", color: "secondary.main" }} />
    </Box>
  );
}
