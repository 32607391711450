"use client";

import { AxiosError } from "axios";
import emitter from "@utils/eventEmitter";

const UNAUTHORIZED_CODE = 401;

const interceptUnauthorized = (error: AxiosError) => {
  // Paylink and embedded checkout handlers
  if (typeof window !== "undefined" && window.location.pathname.includes("/embed")) {
    if (!window.location.pathname.includes("/login") && error.response?.status === UNAUTHORIZED_CODE) {
      let basePath = window.location.pathname.substring(0, window.location.pathname.indexOf("[oid]"));

      const urlParams = new URLSearchParams(window.location.search);
      const mid = urlParams.get("mid");

      if (window.location.pathname.includes("[mid]") && mid) {
        basePath = basePath.replace("[mid]", mid);
      }

      window.location.replace(`${basePath}?redirect=${window.location.pathname}`);
    }
  }

  if (
    (window.location.pathname.includes("/dashboard") || // Interceptor for Shopper Dashboard
      window.location.pathname.includes("/paylink")) && // Intercept app route paylink
    !window.location.pathname.includes("/login") &&
    !window.location.pathname.includes("/otp") &&
    !window.location.pathname.includes("/passkey") &&
    error.response?.status === UNAUTHORIZED_CODE
  ) {
    try {
      emitter.emit("unauthorizedAPIResponse", error);
    } catch (err) {
      console.error("[ShopperDashboard] Error emitting redirect event", err);
    }
  }
  return Promise.reject(error);
};

export default interceptUnauthorized;
