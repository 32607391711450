import { InternalAxiosRequestConfig } from "axios";

import fp, { FingerPrintResponse } from "@services/fingerprint";

const DEVICE_ID_HEADER = "x-device-id";
const FP_REQUEST_ID_HEADER = "x-fp-request-id";

function isMatchingRequestIdHeaderUrl(url?: string): boolean {
  if (!url) {
    return false;
  }
  const patterns = [/\/lookup_by_fingerprint$/];

  return patterns.some((pattern) => pattern.test(url));
}

export const getFingerprintHeaders = async (withRequestIdHeader: boolean = false) => {
  const headers: Record<string, string> = {};

  // Allow real fpjs on stage and prod OR if forced specifically
  let fpResponse: FingerPrintResponse | undefined;
  try {
    //the sdk auto handles session caching and race conditions, only 1 request will be made
    fpResponse = await fp.getFingerprint();
  } catch (e) {
    console.error("[interceptWithFingerprint] failed with:", e);
  }

  if (fpResponse) {
    const { visitorId, requestId } = fpResponse;
    if (visitorId) {
      headers[DEVICE_ID_HEADER] = visitorId;
    }
    // Check if the request is for lookup by fingerprint
    if (requestId && withRequestIdHeader) {
      // Add fingerPrintJs requestId as header
      headers[FP_REQUEST_ID_HEADER] = requestId;
    }
  }

  return headers;
};

const interceptWithFingerprint = async (requestConfig: InternalAxiosRequestConfig) => {
  const headers = await getFingerprintHeaders(isMatchingRequestIdHeaderUrl(requestConfig.url));
  for (const [key, value] of Object.entries(headers)) {
    requestConfig.headers[key] = value;
  }
  return requestConfig;
};

export default interceptWithFingerprint;
